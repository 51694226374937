<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results?.data.flat() || []"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData"
).px-4
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'TableDebtor',
  data () {
    return {
      headers: [],
      actions: [
        {
          id: 1,
          to: (item) => ({
            name: 'distribution-usual-report-crewing-dcoument-type-table',
            params: {
              ...this.$route.params,
              type: item.type === 'passport' ? 'sailor_passport' : 'qual_doc' },
            query: { ...this.$route.query, type_document: item.type } }),
          color: 'blue',
          target: '_blank',
          name: 'mdi-information-outline',
          tooltip: 'tooltip.info',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.listDistributionDocType,
      isLoading: state => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getListDistributionDocType']),
    async getData (params) {
      this.headers = [
        { value: 'name', text: this.$t('typeDoc'), align: 'center', sortable: false },
        { value: 'distribution_sum', text: this.$t('distribution'), align: 'center', sortable: false },
        { value: 'profit_sum', text: this.$t('profit'), align: 'center', sortable: false },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ]
      await this.getListDistributionDocType({ params: { ...params, ...this.$route.params } })
    }
  }
}
</script>
